<template>
    <div>
      <h1
        class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
        style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
      >
        <h6 class="h2 text-white d-inline-block mb-0">
          <i class="ni ni-badge"></i> Surat Izin
        </h6>
      </h1>
  
      <base-header class="pb-6">
     
      </base-header>

  
      <b-container fluid class="mt--6">
        <b-row>
          <b-col xl="12">
            <b-card>
              <section id="mytable">
                  <el-table
                    :data="listData"
                    row-key="id"
                    header-row-class-name="thead-light"
                  >

                    <el-table-column label="No" type="index" width="100" />


                    <el-table-column min-width="300px"  label="Pejabat Penerbit Izin">
                      <div slot-scope="action">
                        {{ action.row.issuing_authority }}
                      </div>
                    </el-table-column>

                    <el-table-column min-width="140px"  label="Tanggal Penerbit Izin">
                      <div slot-scope="action">
                        {{ action.row.publication_date }}
                      </div>
                    </el-table-column>

                    <el-table-column min-width="150px"  label="Masa Berlaku Izin">
                      <div slot-scope="action">
                        {{ action.row.expiration_date}}
                      </div>
                    </el-table-column>

                    <el-table-column min-width="180px"  label="Nomor Izin Pembuangan Air Limbah">
                      <div slot-scope="action">
                        <b>{{ action.row.no}}</b>
                      </div>
                    </el-table-column>

                    <el-table-column min-width="150px"  label="File">
                      <div slot-scope="action">
                        <a :href="`https://${action.row.attachment_file_id}`" target="blank"><button class="btn btn-primary btn-sm">UNDUH</button></a>
                      </div>
                    </el-table-column>


                    <el-table-column min-width="130px"  label="AKSI">
                      <div slot-scope="action">
                          <base-button
                          @click="populateUpdatePermit(action.row)"
                          type="warning"
                          size="sm"
                          icon
                          >
                          UBAH
                          </base-button>
                      </div>
                    </el-table-column>
                  </el-table>


            <div class="py-3">
                <el-select
                  class="select-primary pagination-select mr-3"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  @change="changeSizePage()"
                  >
                  <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>

                <small class="card-category">
                Showing {{  1 }} to {{ listData.length }} of {{ pagination.total }} entries
                </small>
            </div>

            <div style="display: flex;justify-content: flex-end;">
              <base-pagination
                class="pagination-no-border"
                :current="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
                @change="paginationChanged($event)"
              >
              </base-pagination>
            </div>
                 

               
                </section>
                
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <Modal
      :show="showFormModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div>
            <div class="p-3">
              <h2 class="text-center">Perubahan Data Dokumen Perizinan</h2>

              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(updatePermit)">
                  <div class="form-group row mt-4">
                <label class="col-lg-3 col-form-label form-control-label">Izin Pembuangan Air Limbah ke MediaAir</label>
                <div class="col-lg-9">
                  <base-input
                    name="Izin Pembuangan Air Limbah ke Media Air"
                  >
                    <file-input
                      accept="application/pdf"
                      ref="sparing_air_limbah_file"
                      @change="uploadFilePermissionLater('sparing_air_limbah_file','sparing_air_limbah_file')"
                      ></file-input>
                    </base-input>
                    <small>File sebelumnya</small>
                    <a :href="`https://${form.teknis.fileSparing}`"><button class="btn btn-sm btn-primary ml-3">Lihat File</button></a>
                </div>
                  </div>

                  <!-- Pejabat penerbit izin -->
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label">Pejabat Penerbit Izin</label>
                    <div class="col-lg-9">
                      <base-input
                        name="Pejabat Penerbit Izin"
                        rules="required"
                        v-model="form.teknis.permitPerson"
                      ></base-input>
                      <small class="d-block mt--3 text-justify">
                        Contoh Kepala Dinas Modal dan Pelayanan Terpadu Satu Pintu
                        (DPM-PTSP) Provinsi DKI Jakarta
                      </small>
                    </div>
                  </div>

                  <!-- Nomor Izin Pembuangan Air Limbah -->
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label mt--2">Nomor Izin Pembuangan Air Limbah (Definitif)</label>
                    <div class="col-lg-9">
                      <base-input
                        name="Nomor Izin Pembuangan Air Limbah (Definitif)"
                        rules="required"
                        v-model="form.teknis.permitNumber"
                      ></base-input>
                      <small class="d-block mt--3 text-justify">
                        Diisi nomor izin pembuangan air limbah, bukan surat
                        pemenuhan komitmen. Contoh : Nomor :
                        503/KEP.B75BFFFC-PTSP/2019.
                      </small>
                    </div>
                  </div>

                  <!-- Tanggal Izin Terbit -->
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label">Tanggal Izin Terbit</label>
                    <div class="col-lg-9">
                      <base-input
                        class="m-0"
                        rules="required"
                        name="Tanggal Izin Terbit"
                      >
                        <flat-picker
                          slot-scope="{ focus, blur }"
                          @on-open="focus"
                          @on-close="blur"
                          :config="provideSelect.flatPickerConfig"
                          class="form-control datepicker"
                          v-model="form.teknis.permitDatePublish"
                        ></flat-picker>
                      </base-input>
                    </div>
                  </div>

                  <!-- Berlaku -->
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label">Berlaku</label>
                    <div class="col-lg-9">
                      <base-input class="m-0" rules="required" name="Berlaku">
                        <flat-picker
                          slot-scope="{ focus, blur }"
                          @on-open="focus"
                          @on-close="blur"
                          :config="provideSelect.flatPickerConfig"
                          class="form-control datepicker"
                          v-model="form.teknis.permitDateExpire"
                        ></flat-picker>
                      </base-input>
                    </div>
                  </div>

                  <div class="mt-4">
                    <div class="row">
                      <div class="col text-center">
                        <button class="btn bg-secondary text-white border-0" type="submit"> UBAH SURAT IZIN </button>
                        <button class="btn bg-danger border-0 text-white" type="button" @click="showFormModal = false">Tutup</button>
                      </div>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  
    </div>
  </template>
  <script>
  import API from "@/api/base_url.js";
  import { Select, Option, Table, TableColumn } from "element-ui";
  import BaseHeader from "@/components/BaseHeader";
  import FileInput from "@/components/Inputs/FileInput";
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
  import Modal from "@/components/Modal.vue";
  import Swal from "sweetalert2";
  
  
  export default {
    name: "pendaftaranSparing",
    components: {
      Modal,
      BaseHeader,
      FileInput,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      flatPicker,
      [TableColumn.name]: TableColumn,
    },
    mounted() {
      this.getData()
    },
    data() {
      return {
        form: {
          teknis: {
            permitPerson:"",
            permitNumber:"",
            permitDatePublish: "",
            permitDateExpire: "",
            fileSparing:"",
            idUpdate:""
          }
        },
        showFormModal:false,
        permissionLaterForm: new FormData(),
        listData : [],
        pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
        },
        provideSelect: {
          flatPickerConfig: {
            allowInput: true,
            altInput: true,
            altFormat: "d-m-Y H:i",
            enableTime: false,
            time_24hr: true,
            defaultHour: 0,
          },
        },
      };
    },
    methods: {
      getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`permits?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
          .then(({data}) => {
            if (data.data !== null) {
              this.listData = data.data
              this.pagination.total = data.meta.pagination.total_items

            } 
          })
          .catch((err) => {
            Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
            this.isLoading = false
        })

      },
      uploadFilePermissionLater(category, field) {
        this.permissionLaterForm.append(field, this.$refs[field].files[0]);
      },
      updatePermit() {
        Swal.fire({
                title: "Memperbaharui Data ini ?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "UBAH",
                cancelButtonText: "BATAL"
              }).then((result) => {
                if (result.isConfirmed) {
                  let headers = {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                  }

                  let id = this.form.teknis.idUpdate
                  let payload = {
                    attachment_file_id: "36f8ded9-a759-410b-af79-3055fcf25795",
                    expiration_date: this.form.teknis.permitDateExpire,
                    issuing_authority: this.form.teknis.permitPerson,
                    no: this.form.teknis.permitNumber,
                    publication_date: this.form.teknis.permitDatePublish
                  }

            API.put(`permits/${id}` , payload, {headers})
              .then(({data}) => {
                this.showFormModal = false;
                Swal.fire('Data berhasil dirubah','','success')
                this.getData()
              })
              .catch((err) => {
                Swal.fire("Merubah Data Gagal", '', "error");
              })
          }
        });



      
      },
      populateUpdatePermit(data) {
        this.form = {
          teknis: {
            permitPerson: data.issuing_authority,
            permitNumber: data.no,
            permitDatePublish: data.publication_date,
            permitDateExpire: data.expiration_date,
            fileSparing:data.attachment_file_id,
            idUpdate:data.id
          }
        },
        this.showFormModal = true
      },
      changeSizePage() {
        this.getData()
      },
      paginationChanged(page) {
        this.pagination.currentPage = page;
        this.getData()
      },
    },
  };
  </script>
  